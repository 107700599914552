/* //index.css */
@tailwind base;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
}

@tailwind components;
@tailwind utilities;

.footerBG {
  background-image: url(images/layered-waves-footer.svg);
  background-repeat: no-repeat;
  background-size: 100%;
}

/*Context Menu aka the RightClick*/
#context-menu {
  position: fixed;
  z-index: 10000;
  width: 150px;
  background: #1b1a1a;
  border-radius: 5px;
  transform: scale(0);
  transform-origin: top left;
  font-family: 'Open Sans', sans-serif;
}
#context-menu.active {
  transform: scale(1);
  transition: transform 300ms ease-in-out;
}
#context-menu .item {
  padding: 8px 10px;
  font-size: 15px;
  color: #eee;
}
#context-menu .item:hover {
  background: #555;
}
#context-menu .item i {
  display: inline-block;
  margin-right: 5px;
}
#context-menu hr {
  margin: 2px 0px;
  border-color: #555;
}
/*Context Menu aka the RightClick*/
